import React, { useState } from 'react';
import styles from './styles';

import {
	Grid,
	TextField,
	Container,
	Typography,
	Card,
	CardMedia,
	CardContent,
	Autocomplete,
} from '@mui/material';
import Layout from '@components/common/Layouts/Landing';
import Header from '@components/Landing/Help/common/Header';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import SVGEnterprise from '@assets/helpCategories/enterpise.inline.svg';
import SVGQuestions from '@assets/helpCategories/questions.inline.svg';
import SVGComputerCogs from '@assets/helpCategories/computer-cogs.inline.svg';
import SVGLinkHub from '@assets/helpCategories/linkhub-notification.inline.svg';
import SVGNetworking from '@assets/helpCategories/networking.inline.svg';
import { useTheme } from '@mui/material';

const Help: React.FC = () => {
	const theme = useTheme();
	const [searchValue, setSearchValue] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);

	const data = useStaticQuery(graphql`
		{
			allHelpCategory {
				edges {
					node {
						title
						shortName
						description
					}
				}
			}
			allHelpArticle {
				edges {
					node {
						title
						shortName
						category {
							shortName
						}
					}
				}
			}
		}
	`);

	const {
		allHelpCategory: { edges: categories },
		allHelpArticle: { edges: articles },
	} = data;

	const handleOpen = () => {
		// doesnt open the search dropdown until the user starts typing
		if (searchValue.length > 0) {
			setOpen(true);
		}
	};

	const CategoryIcon = ({ shortName }) => {
		if (shortName === 'card-management') {
			return SVGComputerCogs;
		} else if (shortName === 'general') {
			return SVGQuestions;
		} else if (shortName === 'linkhub-management') {
			return SVGLinkHub;
		} else if (shortName === 'enterprise') {
			return SVGEnterprise;
		}
		return SVGNetworking;
	};

	return (
		<Layout>
			<div css={styles(theme)}>
				<Header title="Everything you need to know at a tap">
					<Grid item sm={12}>
						<Autocomplete
							id="free-solo-2-demo"
							className="search"
							fullWidth
							freeSolo
							open={open}
							onInputChange={(_, value) => {
								setSearchValue(value);
							}}
							inputValue={searchValue}
							loadingText="Loading..."
							onOpen={handleOpen}
							onClose={() => setOpen(false)}
							onChange={(event, newValue) => {
								const article = articles?.find(
									({ node: { title } }) => title === newValue
								);
								navigate(
									`/help/${article?.node?.category?.shortName}/${article?.node?.shortName}`
								);
							}}
							options={articles?.map((article) => article?.node?.title)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Enter your questions about Wave Card here..."
									variant="filled"
									sx={{ color: '#000' }}
								/>
							)}
							sx={{ color: '#000' }}
						/>
					</Grid>
					<Grid item sm={12} sx={{ mt: 1 }}>
						<Grid container spacing={1} alignItems="center" justifyContent="center">
							<Grid item>
								<Typography
									variant="h6"
									component="h6"
									marginRight={2}
									className="hero-typography"
								>
									Common topics:
								</Typography>
							</Grid>
							<Grid item>
								<Link to="/help/card-management" className="hero-link">
									<Typography variant="body1">managing my card,</Typography>
								</Link>
							</Grid>
							<Grid item>
								<Link to="/help/linkhub" className="hero-link">
									<Typography variant="body1">managing my linkhub</Typography>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Header>

				<Container maxWidth="md">
					<Grid
						container
						spacing={1}
						direction="row"
						justifyContent="start"
						alignItems="stretch"
						sx={{ py: 3, maxWidth: '800px', margin: 'auto' }}
					>
						{categories?.map((category) => (
							<Grid
								item
								sm={12}
								md={6}
								lg={4}
								className="card-item"
								key={category?.node?.title}
							>
								<Link to={`/help/${category?.node?.shortName}`}>
									<Card className="card" sx={{ height: '100%' }}>
										<CardMedia
											component={CategoryIcon({
												shortName: category?.node?.shortName,
											})}
											sx={{
												maxWidth: 100,
												maxHeight: 100,
												margin: '.75rem auto 0',
											}}
										/>
										<CardContent>
											<Typography
												gutterBottom
												variant="h5"
												component="h3"
												align="center"
											>
												{category?.node?.title}
											</Typography>
											<Typography
												gutterBottom
												variant="body2"
												component="p"
												align="center"
											>
												{category?.node?.description}
											</Typography>
										</CardContent>
									</Card>
								</Link>
							</Grid>
						))}
					</Grid>
				</Container>
			</div>
		</Layout>
	);
};

export default Help;
