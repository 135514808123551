import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.hero-container {
		background-color: #2962ff;
		margin-bottom: 2rem;
		.hero-typography {
			color: #fff;
			margin-right: 0;
		}
		.hero-input {
			border-radius: 4px;
			background-color: #fff;
			margin-bottom: 1rem;
		}
		.hero-link {
			color: #fff;
			text-decoration: underline;
		}
	}

	.card-item {
		width: 100%;
	}

	.card {
		padding: ${theme.spacing(2)};
		transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
		&:hover {
			box-shadow: 0 5px 20px 0 rgb(0 0 0 / 15%);
			transform: scale(1.055) rotate(0.0001deg);
		}
	}

	.search {
		background-color: ${theme.palette.common.white};
		border-radius: ${theme.shape.borderRadius}px;
		overflow: hidden;
		input {
			color: ${theme.palette.common.black};
		}
		&:before {
			border-bottom-style: none;
		}
		&:after {
			border-bottom-color: ${theme.palette.primary.light};
		}
		,
		&:hover {
			background-color: ${theme.palette.common.white};
			&:not(.Mui-disabled) {
				&:before {
					border-bottom-style: none;
				}
			}
		}
		&.Mui-focused {
			background-color: ${theme.palette.common.white};
		}
		&.Mui-disabled {
			&:before {
				border-bottom-style: none;
			}
		}
		&.Mui-error {
			&:after {
				border-bottom-left-radius: ${theme.shape.borderRadius}px;
				border-bottom-right-radius: ${theme.shape.borderRadius}px;
			}
		}
	}
`;
