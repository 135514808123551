import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.hero {
		background-color: ${theme.palette.primary.main};

		.hero-container {
			position: relative;
			max-width: 800px;

			.hero-header {
				padding-bottom: ${theme.spacing(4)};
			}
		}

		.hero-typography {
			color: #fff;
		}
	}
`;
