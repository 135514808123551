import React from 'react';
import styles from './styles';
import { Box, Container, Fab, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { Link } from 'gatsby';
import { Edit } from '@mui/icons-material';
import { HelpCategory } from '@models/helpCategory';
import { HelpArticle } from '@models/helpArticle';
import { useSelector } from 'react-redux';

const Header: React.FC<
	{ type: 'article'; article: HelpArticle } | { type: 'category'; category: HelpCategory }
> = (props) => {
	const { children } = props;
	const theme = useTheme();
	const user = useSelector(({ user }) => user);
	const id =
		(props.type === 'article' && props.article?._id) ||
		(props.type === 'category' && props.category?._id);
	const title =
		(props.type === 'article' && props.article?.title) ||
		(props.type === 'category' && props.category?.title);
	const description =
		(props.type === 'article' && props.article?.category?.title) ||
		(props.type === 'category' && props.category?.description);

	return (
		<div css={styles(theme)}>
			<div className="hero">
				<Grid container justifyContent="start" alignItems="center">
					<Container className="hero-container">
						<Box p={6} my={5}>
							<Grid item sm={12}>
								<Typography
									variant="h1"
									gutterBottom
									className="hero-typography hero-header"
									align="center"
								>
									{props.title}
								</Typography>
								<Typography
									variant="h4"
									component="h2"
									gutterBottom
									className="hero-typography"
									align="center"
								>
									{props.description}
								</Typography>
							</Grid>
							{children}
						</Box>

						{user?.permissions?.isAdmin && (
							<Fab
								color="secondary"
								sx={{ position: 'absolute', bottom: -28, right: 0 }}
								component={Link}
								to={
									props.type === 'article'
										? `/admin/help/articles/${id}`
										: `/admin/help/categories/${id}`
								}
							>
								<Edit />
							</Fab>
						)}
					</Container>
				</Grid>
			</div>
		</div>
	);
};
export default Header;
